import { useState } from "react";
import { useAuthContext } from "../contexts/auth-context";
import { apiRequestSecureGet, apiRequestSecuredPost } from "../lib/common/api";
import {
    TGenericIdName,
    TMatchInfo,
    TProcessStates,
} from "../lib/types/general";
import { TApiTechnician, TTechFilter } from "../lib/types/dentalTechs";
import { getRegions } from "./regions";
import { getIndustryRoles } from "./generalInfo";

export const useTechnicians = () => {
    const { getUserTokenId } = useAuthContext();

    const [state, setState] = useState<TProcessStates>("idle");
    const [regions, setRegions] = useState<TGenericIdName[]>([]);
    const [industryRoles, setIndustryRoles] = useState<TGenericIdName[]>([]);

    const URLS = {
        invitations: "users/technicians/forInvitations",
        search: "users/technicians/search/page",
        searchNames: "users/technicians/search/names",
        searchNamesFromContactList: "users/technicians/frommycontactlist/names",
        contactList: "users/technicians/frommycontactlist/page",
        matches: "users/technicians/matches",
        incognito: "users/technicians/incognito",
    };

    const getMatches = async (): Promise<TMatchInfo[]> => {
        setState("working");
        const data = await apiRequestSecureGet<{ data: TMatchInfo[] }>(
            URLS.matches,
            await getUserTokenId()
        );
        setState("idle");

        return data.data.data;
    };
    const getRegionsForTechs = async () => {
        if (regions.length > 0) return regions;
        const resp = await getRegions();
        setRegions(resp);
    };

    const getPosForTechs = async () => {
        if (industryRoles.length > 0) return industryRoles;
        const resp = await getIndustryRoles();
        setIndustryRoles(resp);
    };

    const getTechList = async (
        filters: TTechFilter,
        url: string,
        pageNumber: string
    ): Promise<{ users: TApiTechnician[]; totalCount: number }> => {
        setState("working");

        const {
            filterName,
            filterPositions,
            filterRegions,
            justInvitationsSent,
        } = filters;

        const [, , response] = await Promise.all([
            getRegionsForTechs(),
            getPosForTechs(),
            apiRequestSecuredPost<{
                users: TApiTechnician[];
                totalCount: number;
            }>(
                pageNumber ? `${url}=${pageNumber}` : url,
                await getUserTokenId(),
                {
                    filter: {
                        positions: filterPositions,
                        regions: filterRegions,
                        name: filterName,
                        justInvitationsSent,
                    },
                }
            ),
        ]);
        const { users, totalCount } = response.data;
        setState("idle");

        return { users, totalCount };
    };
    const getTechsForInvitations = async (filters: TTechFilter) =>
        getTechList(filters, URLS.invitations, ""); //TODO: Fix when implementing pagination for this service;
    const getTechsFromMyContactList = async (
        filters: TTechFilter,
        pageNumber: number
    ) => getTechList(filters, URLS.contactList, pageNumber.toString());

    const getTechSearch = async (filters: TTechFilter, pageNumber: number) =>
        getTechList(filters, URLS.search, pageNumber.toString());
    const getTechSearchNames = async (filters: TTechFilter) =>
        getTechList(filters, URLS.searchNames, ""); //TODO: Fix when implementing pagination for this service
    const getTechSearchNamesFromContactList = async (filters: TTechFilter) =>
        getTechList(filters, URLS.searchNamesFromContactList, ""); //TODO: Fix when implementing pagination fo this service

    const getUnreadInvitations = async (
        filters: TTechFilter,
        pageNumber: number
    ) => {
        return getTechList(
            { ...filters, justInvitationsSent: true },
            URLS.search,
            pageNumber.toString()
        );
    };

    const getTech = async (userId: string): Promise<TApiTechnician[]> => {
        setState("working");
        const response = await apiRequestSecureGet<{ users: TApiTechnician[] }>(
            "users/technicians/" + userId,
            await getUserTokenId()
        );

        setState("idle");

        return response.data.users;
    };

    const incognitoModeApi = async (userId: string, incognito: boolean) => {
        const response = await apiRequestSecuredPost(
            URLS.incognito,
            await getUserTokenId(),
            {
                userId,
                incognito,
            }
        );
        return response.data;
    };

    return {
        state,
        regions,
        industryRoles,
        getMatches,
        getPosForTechs,
        getTechsForInvitations,
        getTechsFromMyContactList,
        getTechSearchNamesFromContactList,
        getTechSearch,
        getTechSearchNames,
        getTech,
        getUnreadInvitations,
        incognitoModeApi,
    };
};
