import React from "react";
import { popover } from "../../common/popover";

export type TRefLabDashboard = {
    refs: {
        searchBtnRef: React.RefObject<any>;
        editProfileRef: React.RefObject<any>;
        suggestionsRef: React.RefObject<any>;
        techlistRef: React.RefObject<any>;
        filterRef: React.RefObject<any>;
        pendinglistRef: React.RefObject<any>;
        helpTipsRef: React.RefObject<any>;
    };
};

export const wizardLabDashboard = ({ refs }: TRefLabDashboard) => {
    const {
        searchBtnRef,
        editProfileRef,
        suggestionsRef,
        techlistRef,
        filterRef,
        pendinglistRef,
        helpTipsRef,
    } = refs;

    const {
        Component: PendingListPopover,
        showHandler: showPendingListHandler,
    } = popover({
        message:
            "These are the technicians you've invited to connect with you, but they haven't accepted your request yet.",
        title: "My Dashboard: 7/7",
        refObject: pendinglistRef,
    });
    const { Component: FilterPopover, showHandler: showFilterHandler } =
        popover({
            message:
                "You can filter techs from your list by name, region or industry role.",
            title: "My Dashboard: 6/7",
            refObject: filterRef,
            nextPopup: showPendingListHandler,
        });
    const { Component: TechListPopover, showHandler: showTechListHandler } =
        popover({
            message:
                "Check out this section where you'll find a list of techs you're connected with. Just a heads up, both you and the tech need to accept the invitation requests to pop up in this list.",
            title: "My Dashboard: 5/7",
            refObject: techlistRef,
            nextPopup: showFilterHandler,
        });
    const {
        Component: SuggestionsPopover,
        showHandler: showSuggestionsHandler,
    } = popover({
        message:
            "Welcome to the WinWinGo AI-powered technician matching section! Here, we present a handpicked list of technicians that we believe are perfect for your lab. These suggestions are based on the positions you are looking to fill, and are aligned with the technician's skills.",
        title: "My Dashboard: 4/7",
        refObject: suggestionsRef,
        nextPopup: showTechListHandler,
    });
    const { Component: HelpTipsPopover, showHandler: helpTipsHandler } =
        popover({
            message:
                "The helpful tips button gives you WinWinGo’s best advice on hiring top techs. These tips are designed to make your lab stand out and connect with the best technicians in the industry.",
            title: "My Dashboard: 3/7",
            refObject: helpTipsRef,
            nextPopup: showSuggestionsHandler,
        });
    const {
        Component: EditProfilePopover,
        showHandler: showEditProfileHandler,
    } = popover({
        message: "Click here to go to your profile page.",
        title: "My Dashboard: 2/7",
        refObject: editProfileRef,
        nextPopup: helpTipsHandler,
    });
    const { Component: SearchLabsPopover, showHandler: showSearchHandler } =
        popover({
            message:
                "Explore all registered technicians in WinWinGo by clicking here. Apply filters and check out basic information for each tech.",
            title: "My Dashboard: 1/7",
            refObject: searchBtnRef,
            nextPopup: showEditProfileHandler,
        });

    return {
        showPopover: showSearchHandler,
        wizardComponent: (
            <>
                {SearchLabsPopover}
                {EditProfilePopover}
                {HelpTipsPopover}
                {SuggestionsPopover}
                {FilterPopover}
                {TechListPopover}
                {PendingListPopover}
            </>
        ),
    };
};
