import { SUPPORT_EMAIL } from "../../lib/common/constants";

export const SupportBtn = () => {
    return (
        <>
            <button
                type="button"
                className="btn mt-1"
                data-toggle="modal"
                data-target="#exampleModal"
            >
                <i className="fa-regular fa-circle-question text-white fs-5"></i>
            </button>

            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-primary">
                            <h1
                                className="modal-title fs-5"
                                id="helpModalLabel"
                            >
                                {" "}
                                <i className="fa-regular fa-circle-question me-3 fs-lg"></i>
                                Contact Support
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body p-5">
                            <p className="fw-bold">
                                Need help? <br />
                                Send us an email, and we’ll get back to you as
                                soon as possible!
                            </p>
                            <a
                                className="btn-link text-primary fw-bold"
                                href={"mailto:" + SUPPORT_EMAIL}
                            >
                                {SUPPORT_EMAIL}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
