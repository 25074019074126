import React from "react";
import { popover } from "../../common/popover";

export type TRefTechDashboard = {
    refs: {
        searchBtnRef: React.RefObject<any>;
        editProfileRef: React.RefObject<any>;
        suggestionsRef: React.RefObject<any>;
        lablistRef: React.RefObject<any>;
        blackListRef: React.RefObject<any>;
        incognitoBtnRef: React.RefObject<any>;
        helpTipsRef: React.RefObject<any>;
    };
};

export const wizardTechDashboard = ({ refs }: TRefTechDashboard) => {
    const {
        searchBtnRef,
        editProfileRef,
        suggestionsRef,
        lablistRef,
        blackListRef,
        incognitoBtnRef,
        helpTipsRef,
    } = refs;

    const { Component: BlackListPopover, showHandler: showBlackListHandler } =
        popover({
            message:
                "The Block List ensures that specified labs cannot view your profile. Your profile will not appear in their search results nor recommendations section, and if they attempt to access it, they will see a 'Profile Does Not Exist' message.",
            title: "My Dashboard: 7/7",
            refObject: blackListRef,
        });
    const { Component: LabListPopover, showHandler: showLabListHandler } =
        popover({
            message:
                "Check out this section where you'll find a list of labs you're connected with. Just a heads up, both you and the labs need to accept the invitation requests to pop up in this list.",
            title: "My Dashboard: 6/7",
            refObject: lablistRef,
            nextPopup: showBlackListHandler,
        });
    const {
        Component: SuggestionsPopover,
        showHandler: showSuggestionsHandler,
    } = popover({
        message:
            "Welcome to the WinWinGo AI-powered recommendation section! Here, we present a curated list of labs that we believe are perfect for you. These suggestions are tailored based on your hobbies and interests, and are aligned with the lab's description and location.",
        title: "My Dashboard: 5/7",
        refObject: suggestionsRef,
        nextPopup: showLabListHandler,
    });
    const { Component: HelpfulTipsPopover, showHandler: helpfulTipsHandler } =
        popover({
            message:
                "The helpful tips button is your go-to resource for advice curated by WinWinGo. Click it to discover valuable suggestions, such as how to craft a standout resume, optimize your profile, and approach labs confidently to land your dream job. It's designed to guide you toward success in your career journey.",
            title: "My Dashboard: 4/7",
            refObject: helpTipsRef,
            nextPopup: showSuggestionsHandler,
        });
    const {
        Component: EditProfilePopover,
        showHandler: showEditProfileHandler,
    } = popover({
        message: "Click here to go to your profile page.",
        title: "My Dashboard: 3/7",
        refObject: editProfileRef,
        nextPopup: helpfulTipsHandler,
    });
    const { Component: SearchLabsPopover, showHandler: showSearchHandler } =
        popover({
            message:
                "Click here to see a list of all available laboratories. You will be able to apply filters and view basic information about each lab.",
            title: "My Dashboard: 2/7",
            refObject: searchBtnRef,
            nextPopup: showEditProfileHandler,
        });
    const {
        Component: IncognitoEyePopover,
        showHandler: showIncognitoEyeHandler,
    } = popover({
        message:
            "The incognito eye symbol lets you know whether your profile is visible or hidden from others. When the eye is crossed, your profile is in incognito mode ON, meaning labs and other users won't be able to see your information. If the eye is open, incognito mode is OFF, and your profile becomes visible. You can go to your profile to easily edit your incognito status.",
        title: "My Dashboard: 1/7",
        refObject: incognitoBtnRef,
        nextPopup: showSearchHandler,
    });

    return {
        showPopover: showIncognitoEyeHandler,
        wizardComponent: (
            <>
                {IncognitoEyePopover}
                {SearchLabsPopover}
                {EditProfilePopover}
                {HelpfulTipsPopover}
                {SuggestionsPopover}
                {LabListPopover}
                {BlackListPopover}
            </>
        ),
    };
};
