import { MouseEvent, useEffect, useRef, useState } from "react";
import { TApiTechnician } from "../../../lib/types/dentalTechs";
import { useTechnicians } from "../../../services/technicians";
import { useRouteNavigation } from "../../../lib/common/routes";
import { useFileManagement } from "../../../services/file-management";
import {
    DentalTechSearchFilterPanel,
    TTechnicianFilterSettings,
} from "../../common/dental-tech-search-filter-panel";
import { useUserService } from "../../../services/users";
import { TGenericIdName } from "../../../lib/types/general";
import { getCacheKillQueryString } from "../../../lib/common/api";
import { useServiceContext } from "../../../contexts/service";
import {
    ASSETS_ROUTES,
    TECH_SEARCH_PAGINATION_SIZE,
} from "../../../lib/common/constants";
import { Pagination } from "../../common/pagination";
import { Spinner } from "../../common/spinner";
import { useNotificationContext } from "../../../contexts/notification-context";
import { MessageBtn } from "../../common/message-button";

type TDashboardDentalTechRowProps = {
    userInfo: TApiTechnician;
    regionList: TGenericIdName[];
    industryRoles: TGenericIdName[];
    refFilter?: React.RefObject<any>;
    removeContactCallback: () => {};
};

const currencyFormater = new Intl.NumberFormat();

export const DashboardDentalTechRow = (props: TDashboardDentalTechRowProps) => {
    const { userInfo, removeContactCallback, regionList, industryRoles } =
        props;
    const { navigateRouteClickHandler } = useRouteNavigation();
    const { getImagePath } = useFileManagement();
    const [logoImg, setLogo] = useState<string | undefined>(undefined);
    const { removeFromMyContactList } = useUserService();
    const goToTechClickHandler = (e: MouseEvent) => {
        navigateRouteClickHandler("dentalTechInfo", e, userInfo.id, true);
    };
    const {
        chats: { setShowChat },
    } = useServiceContext();

    useEffect(() => {
        if (userInfo?.images?.logo) {
            setLogo(getImagePath(userInfo.images.logo, "fixed", userInfo.id));
            getCacheKillQueryString();
        } else {
            setLogo(ASSETS_ROUTES.IMGS.techAvatarUrl);
        }
        //eslint-disable-next-line
    }, []);
    const deleteContactClickHandler = async (
        e: MouseEvent,
        contactUserId: string
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setShowChat(false);
        await removeFromMyContactList(contactUserId);
        removeContactCallback();
    };

    return (
        <div className="list-group-item list-group-item-action border border-light py-4">
            <div className="row text-center gap-3">
                <div
                    className="col-md-3 ps-4"
                    onClick={goToTechClickHandler}
                    role="button"
                >
                    <img
                        className="rounded-circle list-logo "
                        alt="imgcq"
                        src={logoImg}
                        width="100"
                        height="100"
                        data-testid="test-tech-logo"
                    />
                </div>
                <div
                    className="col-md-5 text-center text-md-start"
                    onClick={goToTechClickHandler}
                    role="button"
                >
                    <p className="btn-link">
                        <strong>{userInfo.name}</strong>
                    </p>
                    {userInfo.other.positions && (
                        <p className="mb-0">
                            {" "}
                            <small>
                                {userInfo.other.positions
                                    .map((positionId) => {
                                        const position = industryRoles.find(
                                            (role) => role.id === positionId
                                        );
                                        return position ? position.name : null;
                                    })
                                    .join(", ")}
                            </small>
                        </p>
                    )}
                    <p className="mb-0">
                        <small>
                            {userInfo.other.yearsOfExperience} Years Exp.
                        </small>
                    </p>
                    {userInfo.other.desiredRegion !== undefined && (
                        <p className="mb-0">
                            <small>
                                Region of Interest:{" "}
                                {
                                    regionList.find(
                                        (region) =>
                                            region.id ===
                                            userInfo.other.desiredRegion
                                    )?.name
                                }
                            </small>
                        </p>
                    )}
                    {userInfo.other.desiredSalaryPerYear !== undefined && (
                        <p className="mb-0">
                            <small>
                                Desired Salary: $
                                {currencyFormater.format(
                                    userInfo.other.desiredSalaryPerYear
                                )}{" "}
                                yearly
                            </small>
                        </p>
                    )}
                    {/* <div className="mt-3">
                        <a
                            className="btn-link text-decoration-none"
                            href={getRoute("dentalTechInfo", userInfo.id)}
                            >
                            </a>
                            </div> */}
                </div>
                <div className="col-md-3">
                    <MessageBtn
                        userInfo={userInfo}
                        className="btn-secondary mb-2 w-75 pe-4 "
                    />
                    <button
                        className="btn btn-primary mb-2 w-75 pe-4"
                        onClick={(e) =>
                            navigateRouteClickHandler(
                                "dentalTechInfo",
                                e,
                                userInfo.id,
                                true
                            )
                        }
                    >
                        <i className="fa-regular fa-user me-2"></i> View
                    </button>
                    <button
                        onClick={(e) =>
                            deleteContactClickHandler(e, userInfo.id)
                        }
                        className="btn btn-outline-danger w-75 "
                    >
                        <i className="fa-regular fa-trash-can me-2"></i>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

//
type TTechListProps = {
    refFilter?: React.RefObject<any>;
    refTechList?: React.RefObject<HTMLDivElement>;
    industryRoles: TGenericIdName[];
};
export const TechList = ({
    refFilter,
    industryRoles,
    refTechList,
}: TTechListProps) => {
    const { getTechsFromMyContactList, state, regions } = useTechnicians();
    const [technicians, setTechnicians] = useState<TApiTechnician[]>([]);
    const nameFilter = useRef<string[]>();
    const regionFilters = useRef<string[]>();
    const industryRolesFilters = useRef<string[]>();
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const { setActionsHandler } = useNotificationContext();

    const refreshList = async (offset: number) => {
        const techs = await getTechsFromMyContactList(
            {
                filterRegions: regionFilters.current,
                filterPositions: industryRolesFilters.current,
                filterName: nameFilter.current,
            },
            offset
        );
        setTechnicians(techs.users);
        setTotalCount(techs.totalCount);
        setPageNumber(offset + 1);
    };

    const resetCallback = () => {
        regionFilters.current = undefined;
        industryRolesFilters.current = undefined;
        nameFilter.current = undefined;
        refreshList(0);
    };

    const filterCallback = async (
        filterSettings: TTechnicianFilterSettings
    ) => {
        const { regions, industryRoles, names } = filterSettings;

        regionFilters.current = regions ? regions.map((o) => o.data.id) : [];
        industryRolesFilters.current = industryRoles
            ? industryRoles.map((o) => o.data.id)
            : [];
        nameFilter.current = names ? names.map((o) => o.data.name) : [];

        await refreshList(0);
    };

    useEffect(() => {
        setActionsHandler("refreshContactList", () => refreshList(0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className="container-fluid rounded mb-3 pt-3 pb-2"
                ref={refFilter}
            >
                <DentalTechSearchFilterPanel
                    filterCallback={filterCallback}
                    resetCallback={resetCallback}
                    searchJustFromContactList={true}
                />
            </div>
            <h4 className="p-3" ref={refTechList}>
                Job Candidates
            </h4>
            <div className="list-group">
                {state === "working" && (
                    <>
                        <Spinner />
                    </>
                )}
                {state === "idle" && technicians.length === 0 && (
                    <p className="p-3">You don't have any contacts yet.</p>
                )}
                {state === "idle" && technicians.length > 0 && (
                    <>
                        {technicians.map((techInfo) => (
                            <DashboardDentalTechRow
                                key={techInfo.id}
                                userInfo={techInfo}
                                removeContactCallback={() => refreshList(0)}
                                regionList={regions}
                                industryRoles={industryRoles}
                            />
                        ))}
                        {
                            <Pagination
                                currentPage={pageNumber}
                                totalCount={totalCount}
                                itemsPerPage={TECH_SEARCH_PAGINATION_SIZE}
                                refObject={refTechList}
                                onPageChange={(offset) => {
                                    refreshList(offset);
                                }}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};

export const PendingTechContacts = (props: TDashboardDentalTechRowProps) => {
    const { userInfo, industryRoles, regionList } = props;
    const { navigateRouteClickHandler } = useRouteNavigation();
    const { getImagePath } = useFileManagement();
    const [logoImg, setLogo] = useState<string | undefined>(undefined);
    // const { removeFromMyContactList } = useUserService();
    const goToTechClickHandler = (e: MouseEvent) => {
        navigateRouteClickHandler("dentalTechInfo", e, userInfo.id, true);
    };

    useEffect(() => {
        if (userInfo?.images?.logo) {
            setLogo(getImagePath(userInfo.images.logo, "fixed", userInfo.id));
        } else {
            setLogo(ASSETS_ROUTES.IMGS.techAvatarUrl);
        }
        //eslint-disable-next-line
    }, []);
    /* const deleteContactClickHandler = async (e: MouseEvent, contactUserId: string) => {
         e.preventDefault();
         e.stopPropagation();
 
         await removeFromMyContactList(contactUserId);
         removeContactCallback();
     }*/

    return (
        <div className="list-group-item list-group-item-action border border-light py-4">
            <div
                className="row text-center gap-3"
                onClick={goToTechClickHandler}
                role="button"
            >
                <div className="col-md-3">
                    <img
                        className="rounded-circle list-logo"
                        alt="imgcq"
                        src={
                            userInfo.incognito
                                ? ASSETS_ROUTES.IMGS.incognitoTechUrl
                                : logoImg
                        }
                        width="100"
                        height="100"
                        data-testid="test-tech-logo"
                    />
                </div>
                <div
                    className="col-md-7 text-center text-md-start"
                    onClick={goToTechClickHandler}
                    role="button"
                >
                    <p className="btn-link">
                        <strong>{userInfo.name}</strong>
                    </p>
                    <p className="mb-0">
                        <small>
                            {userInfo.other.yearsOfExperience} Years Exp.
                        </small>
                    </p>
                    {userInfo.other.positions && (
                        <small className="">
                            {userInfo.other.positions
                                .map((positionId) => {
                                    const position = industryRoles.find(
                                        (role) => role.id === positionId
                                    );
                                    return position ? position.name : null;
                                })
                                .join(", ")}
                        </small>
                    )}
                    {userInfo.other.desiredRegion !== undefined && (
                        <p className="mb-0">
                            <small>
                                Region of interest:{" "}
                                {
                                    regionList.find(
                                        (region) =>
                                            region.id ===
                                            userInfo.other.desiredRegion
                                    )?.name
                                }
                            </small>
                        </p>
                    )}
                    {userInfo.other.desiredSalaryPerYear !== undefined && (
                        <p className="mb-0">
                            <small>
                                Desired Salary: $
                                {currencyFormater.format(
                                    userInfo.other.desiredSalaryPerYear
                                )}{" "}
                                yearly
                            </small>
                        </p>
                    )}
                </div>
                <div className="d-none d-md-flex col-md-1 align-items-center text-end">
                    <i className="fa-solid fa-chevron-right btn-link text-decoration-none"></i>
                </div>
            </div>
        </div>
    );
};
export const UnreadInvitationsList = ({
    refPendingList,
}: {
    refPendingList?: React.RefObject<any>;
}) => {
    const { getUnreadInvitations, state, regions, industryRoles } =
        useTechnicians();
    const [unreadInvitations, setUnreadInvitations] = useState<
        TApiTechnician[]
    >([]);
    const nameFilter = useRef<string[]>();
    const regionFilters = useRef<string[]>();
    const industryRolesFilters = useRef<string[]>();
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const refreshList = async (offset: number) => {
        const techs = await getUnreadInvitations(
            {
                filterRegions: regionFilters.current,
                filterPositions: industryRolesFilters.current,
                filterName: nameFilter.current,
            },
            offset
        );
        setUnreadInvitations(techs.users);
        setTotalCount(techs.totalCount);
        setPageNumber(offset + 1);
    };

    useEffect(() => {
        refreshList(0);
        //eslint-disable-next-line
    }, []);
    return (
        <>
            <div className="list-group">
                {state === "working" && <Spinner />}
                {state === "idle" && unreadInvitations.length > 0 && (
                    <>
                        {unreadInvitations.map((invitationInfo) => (
                            <PendingTechContacts
                                key={invitationInfo.id}
                                userInfo={invitationInfo}
                                removeContactCallback={() => refreshList(0)}
                                regionList={regions}
                                industryRoles={industryRoles}
                            />
                        ))}
                        {
                            <Pagination
                                currentPage={pageNumber}
                                totalCount={totalCount}
                                itemsPerPage={TECH_SEARCH_PAGINATION_SIZE}
                                refObject={refPendingList}
                                onPageChange={(offset) => {
                                    refreshList(offset);
                                }}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};
