import { useEffect } from "react";
import { SUPPORT_EMAIL } from "../../lib/common/constants";

export const WelcomeModal = () => {
    useEffect(() => {
        const modalElement = document.getElementById("welcomeModal");

        if (modalElement) {
            const modal = new window.bootstrap.Modal(modalElement);
            modal.show();
        }
    }, []);

    return (
        <div
            className="modal fade"
            id="welcomeModal"
            tabIndex={-1}
            aria-labelledby="modalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content bg-dark text-white rounded mx-3">
                    <div className="modal-header justify-content-center border-0">
                        <h1
                            className="modal-title highlighted-title"
                            id="modalLabel"
                        >
                            Welcome Aboard!
                        </h1>
                        {/* <button type="button" className="btn-close btn-close-white border border-light border-3" data-dismiss="modal" aria-label="Close"></button> */}
                    </div>
                    <div className="modal-body text-center border-0">
                        <h4>Let’s start your next chapter!</h4>
                        <br />
                        <h5 className="lh-base px-4">
                            {" "}
                            Complete your profile to get the most out of our
                            platform. This will help us match you with the best
                            labs!
                        </h5>
                    </div>
                    <div className="modal-footer border-0">
                        <button
                            type="button"
                            className="btn btn-info w-100"
                            data-dismiss="modal"
                        >
                            Let's Go!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const WelcomeLabModalFreeBetaVersion = () => {
    useEffect(() => {
        const modalElement = document.getElementById(
            "welcomeLabModalFreeBetaVersion"
        );

        if (modalElement) {
            const modal = new window.bootstrap.Modal(modalElement);
            modal.show();
        }
    }, []);

    return (
        <div
            className="modal fade"
            id="welcomeLabModalFreeBetaVersion"
            tabIndex={-1}
            aria-labelledby="modalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                <div className="modal-content bg-dark text-white rounded p-3 text-center">
                    <div className="modal-header justify-content-center border-0">
                        <h2
                            className="modal-title highlighted-title"
                            id="modalLabel"
                        >
                            Welcome to the Beta Version of WinWinGo!
                        </h2>
                    </div>
                    <div className="modal-body">
                        <h3 className=" ">
                            ⭐ FREE ACCESS ⭐
                            <br />
                            FOR LIMITED TIME ONLY
                        </h3>
                        <br />
                        <p className="lh-base">
                            Take a moment to personalize your profile and start
                            connecting with top dental techs.
                            <br />
                        </p>
                        <p className="mt-3 lh-base">
                            Thank you for joining us during our
                            <br />
                            <strong> BETA development stage! </strong>
                            <br /> <br />
                            If you spot an issue or have ideas for improvement,
                            we’d love to hear from you at{" "}
                            <a
                                className="btn-link text-primary"
                                href={"mailto:" + SUPPORT_EMAIL}
                            >
                                {" "}
                                {SUPPORT_EMAIL}
                            </a>
                            .
                            <br /> <br />
                            Your feedback helps us build the best dental
                            employment platform on the web!
                        </p>
                    </div>
                    <div className="modal-footer border-0">
                        <button
                            type="button"
                            className="btn btn-primary   w-100 fw-bold"
                            data-dismiss="modal"
                        >
                            Go to My Profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
