import { AxiosResponse } from "axios";
import {
    apiRequestSecureGet,
    apiRequestSecuredPost,
    apiRequestSecuredPut,
} from "../lib/common/api";
import { TMessage, TMessages, TProcessStates } from "../lib/types/general";
import { TMessageStatus } from "../lib/types/general";
import { useState } from "react";
import { useAuthContext } from "../contexts/auth-context";

const endpointAPIMessages = "users/messages";
const endpointAPIInvitations = "users/messages/invitation";

export const getNotifications = async (
    userTokenId: string
): Promise<AxiosResponse<TMessages>> => {
    return await apiRequestSecureGet<TMessages>(
        endpointAPIMessages,
        userTokenId
    );
};

const setInvitationState = async (
    userTokenId: string,
    toUserId: string,
    state: TMessageStatus
) => {
    await apiRequestSecuredPut<any>(endpointAPIInvitations, userTokenId, {
        toUserId,
        state,
    });
};

export const acceptInvitation = async (
    userTokenId: string,
    toUserId: string
) => {
    return setInvitationState(userTokenId, toUserId, "accepted");
};

export const rejectInvitation = async (
    userTokenId: string,
    toUserId: string
) => {
    return setInvitationState(userTokenId, toUserId, "rejected");
};

export const setMessageState = async (
    message: TMessage,
    userTokenId: string,
    state: TMessageStatus
) => {
    return await apiRequestSecuredPut<any>(endpointAPIMessages, userTokenId, {
        messageId: message.id,
        state,
    });
};

export const setMessageRead = async (
    message: TMessage,
    userTokenId: string
) => {
    setMessageState(message, userTokenId, "read");
};

export const useMessages = (userId: string) => {
    const [sendInviteState, setSendInviteState] =
        useState<TProcessStates>("idle");

    const { getUserTokenId } = useAuthContext();

    const sendInvitation = async (note?: string) => {
        if (sendInviteState !== "idle") return;
        setSendInviteState("working");

        try {
            await apiRequestSecuredPost<any>(
                endpointAPIInvitations,
                await getUserTokenId(),
                {
                    toUserId: userId,
                    message: note,
                }
            );

            setSendInviteState("done");
        } catch (error) {
            setSendInviteState("error");
        }

        return "ok";
    };

    return {
        sendInviteState,
        sendInvitation,
    };
};
