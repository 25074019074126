import { useAuthContext } from "../../../contexts/auth-context";
import { ASSETS_ROUTES } from "../../../lib/common/constants";
import { useRouteNavigation } from "../../../lib/common/routes";
import { RouteEnumType } from "../../../lib/types/routes";
import { DashboardLink, MyAccountLink, MyHomeLink } from "../../common/header";
import { SignInAndOut } from "../../common/user-signin-modal";
import {
    evaluators,
    useWindowSizeMatch,
} from "../../../lib/window-size-matcher";
import { NotificationsV2 } from "../../common/notifications-v2";
import { SupportBtn } from "../../common/supportbutton";
import { BottomFixedBadge } from "../../common/bottom-fixed-badge";
export const TopMenu = () => {
    const { userInfo, isLoggedIn } = useAuthContext();
    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();
    const { conditionFulfilled: isSmallDevice } = useWindowSizeMatch({
        condition: evaluators.lessThan(991),
    });
    const homeRoute: RouteEnumType = isLoggedIn ? "homeRegistered" : "home";
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
                <div className="container-fluid">
                    <div className="d-flex">
                        <a
                            href={getRoute(homeRoute)}
                            onClick={(e) =>
                                navigateRouteClickHandler(homeRoute, e)
                            }
                        >
                            <img
                                src={ASSETS_ROUTES.IMGS.logo}
                                className="logo"
                                alt="WinWinGo"
                                title="WinWinGo"
                            />
                        </a>
                        <BottomFixedBadge />
                    </div>
                    <div className="d-flex align-items-start">
                        <div className="d-block d-lg-none">
                            {/* {isLoggedIn && <Notifications />}{" "} */}
                            {isSmallDevice.fulfilled && (
                                <NotificationsV2 showForSmallDevice={true} />
                            )}{" "}
                        </div>
                        {isSmallDevice.fulfilled && <SupportBtn />}{" "}
                        <button
                            className="navbar-toggler border-0 rounded-3"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon fs-1 me-0"></span>
                        </button>
                    </div>
                    <div
                        className="collapse navbar-collapse justify-content-end text-center  me-4"
                        id="navbarNavAltMarkup"
                    >
                        <div className="navbar-nav ms-4">
                            <MyHomeLink />
                            <a
                                className="nav-link nav-row rounded-3 ms-2"
                                href={getRoute("aboutMark")}
                            >
                                About
                            </a>
                            {userInfo?.type && isLoggedIn ? (
                                <>
                                    <DashboardLink />
                                    <MyAccountLink />
                                </>
                            ) : (
                                <>
                                    <a
                                        className="nav-link nav-row rounded-3 ms-2"
                                        href={getRoute("story")}
                                        onClick={(e) =>
                                            navigateRouteClickHandler(
                                                "story",
                                                e
                                            )
                                        }
                                    >
                                        Story
                                    </a>
                                    <a
                                        className="nav-link nav-row rounded-3 ms-2"
                                        href={getRoute("pricing")}
                                        onClick={(e) =>
                                            navigateRouteClickHandler(
                                                "pricing",
                                                e
                                            )
                                        }
                                    >
                                        Pricing
                                    </a>
                                </>
                            )}
                            <div className="d-none d-lg-block ms-3">
                                {/* {isLoggedIn && <Notifications />}{" "} */}
                                {!isSmallDevice.fulfilled && (
                                    <NotificationsV2
                                        showForSmallDevice={false}
                                    />
                                )}{" "}
                            </div>
                            {!isSmallDevice.fulfilled && <SupportBtn />}
                            <SignInAndOut />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};
