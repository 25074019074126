import { useEffect, useState } from "react";
import { apiRequestGet } from "../../../lib/common/api";
import { TTestimonial } from "../../../lib/types/testimonials";
import { useFileManagement } from "../../../services/file-management";

const useTestimonials = () => {
    const [testimonials, setTestimonials] = useState<TTestimonial[]>([]);
    const [peopleImages, setPeopleImages] = useState<{
        [userId: string]: string;
    }>({});

    const { getImagePath } = useFileManagement();

    const getTestimonials = async () => {
        const testimonials = await apiRequestGet<{
            testimonials: TTestimonial[];
        }>("/testimonials/all");
        return testimonials.data;
    };

    useEffect(() => {
        getTestimonials().then((data) => {
            setTestimonials(data.testimonials);
            const images = data.testimonials.reduce(
                (prev, actual) => ({
                    ...prev,
                    [actual.user_id]: getImagePath(
                        "logo",
                        "fixed",
                        actual.user_id
                    ),
                }),
                {}
            );
            setPeopleImages(images);
        });
        //eslint-disable-next-line
    }, []);

    return {
        testimonials,
        peopleImages,
    };
};

type TTestimonyProp = {
    testimony: TTestimonial;
    imageURL: string;
};
const Testimony = (props: TTestimonyProp) => {
    const { testimony, imageURL } = props;

    return (
        <>
            <div className="container testimony-text p-4 mb-5">
                <blockquote className="blockquote">
                    <p className="small" data-testid="testimony-test">
                        {testimony.testimony}
                    </p>
                    <footer className="blockquote-footer mt-4 me-4 pe-5 text-end">
                        {testimony.name}
                        <img
                            className="testimonial-picture"
                            src={imageURL}
                            alt="testimony"
                            data-testid="photo-test"
                        />
                    </footer>
                </blockquote>
            </div>
        </>
    );
};

export const Testimonials = () => {
    const { testimonials, peopleImages } = useTestimonials();

    return (
        <>
            {/*<div className="container-fluid testimonials">
                <div className="row">
                    <div className="col-md-12">
                        <img alt="testimonials" src="images/global/testimonials.jpg" style={{ maxWidth: "100%", height: "auto" }} />
                    </div>
                </div>
            </div > */}
            {testimonials.length > 0 && (
                <div className="container-fluid section-testimonials p-3 m-0">
                    <div className="container text-center my-5 text-wrap">
                        <h1>What our clients say ...</h1>
                    </div>
                    <div className="container">
                        <div className="row px-4 pb-5 text-left m-0">
                            <div className="col-sm-6 col-md-4 testimony-section">
                                <Testimony
                                    imageURL={
                                        peopleImages[testimonials[0].user_id]
                                    }
                                    testimony={testimonials[0]}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 testimony-section">
                                <Testimony
                                    imageURL={
                                        peopleImages[testimonials[1].user_id]
                                    }
                                    testimony={testimonials[1]}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 testimony-section">
                                <Testimony
                                    imageURL={
                                        peopleImages[testimonials[2].user_id]
                                    }
                                    testimony={testimonials[2]}
                                />
                            </div>
                            <div className="col-sm-6 col-md-7 testimony-section">
                                <Testimony
                                    imageURL={
                                        peopleImages[testimonials[3].user_id]
                                    }
                                    testimony={testimonials[3]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
