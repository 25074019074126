export const BottomFixedBadge = () => {
    return (
        <>
            <div className="betaBadge my-2">
                <p>
                    <span className="badge text-bg-warning border-dark border-3 border font-monospace">
                        BETA
                    </span>
                </p>
            </div>
        </>
    );
};
