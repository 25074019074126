import { useEffect, useState } from "react";
import {
    TAcceptRejectInvitationClickHandler,
    useNotificationContext,
} from "../../../contexts/notification-context";
import {
    TInviteMessage,
    TMessage,
    TProcessStates,
} from "../../../lib/types/general";
import { useRouteNavigation } from "../../../lib/common/routes";
import { RouteEnumType } from "../../../lib/types/routes";
import { useFileManagement } from "../../../services/file-management";
import { TUserToChatInfo, useServiceContext } from "../../../contexts/service";
import { ASSETS_ROUTES } from "../../../lib/common/constants";

type TNotificationComponent = {
    invitation: TInviteMessage;
    acceptClickHandler: TAcceptRejectInvitationClickHandler;
    rejectClickHandler: TAcceptRejectInvitationClickHandler;
};

type TMessageComponent = {
    message: TMessage;
};

export const Messages = (params: TMessageComponent) => {
    const { message } = params;
    const { navigateToRoute, getRoute } = useRouteNavigation();
    const {
        chats: { setShowChat, setUserIdToChat },
    } = useServiceContext();
    const { setMessageReadClickHandler } = useNotificationContext();

    let whereToGo: RouteEnumType = "home";
    let userId: string = message.isFromChats
        ? (message.action_data.data as TUserToChatInfo).id
        : message.action_data.data;

    if (!message.isFromChats && message.action_data.goto)
        whereToGo = message.action_data.goto as RouteEnumType;

    const clickEventHandler = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (!message.isFromChats && message.action_data.goto) {
            navigateToRoute(whereToGo, message.action_data.data);
        }
        if (message.isFromChats) {
            setUserIdToChat(message.action_data.data as TUserToChatInfo);
            setShowChat(true);
            setMessageReadClickHandler(message.id);
        }
    };

    const { getImagePath } = useFileManagement();
    return (
        <div className="list-group-item list-group-item-action border border-light ">
            <div className="row" data-testid="message-test">
                <div className="col-md-2">
                    {/* <i className="fa-solid fa-circle-info fa-2xl" data-testid="info-icon-test"></i>*/}
                    <img
                        alt="img-logo"
                        className="notification-logo-user rounded-circle"
                        src={getImagePath("logo", "fixed", userId)}
                        data-testid="info-icon-test"
                        onError={(e) => {
                            (e.target as any).src =
                                ASSETS_ROUTES.IMGS.techAvatarUrl;
                        }}
                    />
                </div>
                <div className="col-md-10">
                    <a
                        className="btn-link text-decoration-none lh-1"
                        href={getRoute(whereToGo, message.action_data.data)}
                        onClick={clickEventHandler}
                    >
                        <p>
                            {" "}
                            <small>{message.title}</small>{" "}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    );
};

export const Notification = (params: TNotificationComponent) => {
    const { invitation, acceptClickHandler, rejectClickHandler } = params;
    const [state, setState] = useState<TProcessStates>("idle");
    const { getImagePath } = useFileManagement();
    const [imgLogo, setImgLogo] = useState<string | null>(null);
    const [showFullMessage, setShowFullMessage] = useState(false);

    useEffect(() => {
        setImgLogo(getImagePath("logo", "fixed", invitation.from));
        // getCacheKillQueryString();
        //eslint-disable-next-line
    }, []);

    const acceptInviteClickHandler = async () => {
        setState("working");
        await acceptClickHandler(invitation.from);
        setState("done");
    };

    const rejectInviteClickHandler = async () => {
        setState("working");
        await rejectClickHandler(invitation.from);
        setState("done");
    };
    const { getRoute, navigateRouteClickHandler } = useRouteNavigation();

    const infoSectionBasedOnInviteFromType: RouteEnumType =
        invitation.from_user_type === "lab"
            ? "dentalLabInfo"
            : "dentalTechInfo";
    const infoRoute = getRoute(
        infoSectionBasedOnInviteFromType,
        invitation.from
    );

    const toggleMessage = () => {
        setShowFullMessage(!showFullMessage);
    };

    const renderMessage = () => {
        if (!invitation.message) return null;

        const words = invitation.message.split(" ");
        const initialWords = words.slice(0, 10).join(" ");

        return showFullMessage ? (
            <p className="card-text">
                {invitation.message}{" "}
                <button
                    className="btn btn-link p-0 showLessBtn"
                    onClick={toggleMessage}
                >
                    Show Less
                </button>
            </p>
        ) : (
            <p className="card-text">
                {initialWords}
                {words.length > 10 && "... "}
                {words.length > 10 && (
                    <button
                        className="btn btn-link p-0 showLessBtn"
                        onClick={toggleMessage}
                    >
                        See More
                    </button>
                )}
            </p>
        );
    };
    return (
        <div className="list-group-item list-group-item-action border border-light">
            <div className="row" data-testid="invitation-test">
                <div className="col-md-2">
                    {imgLogo && (
                        <img
                            className="notification-logo-user rounded-circle"
                            role="button"
                            alt="hgap"
                            src={imgLogo}
                            onError={() => {
                                if (invitation.from_user_type === "lab") {
                                    setImgLogo(ASSETS_ROUTES.IMGS.labAvatarUrl);
                                }
                                if (invitation.from_user_type === "tech") {
                                    setImgLogo(
                                        ASSETS_ROUTES.IMGS.techAvatarUrl
                                    );
                                }
                            }}
                            onClick={(e) =>
                                navigateRouteClickHandler(
                                    infoSectionBasedOnInviteFromType,
                                    e,
                                    invitation.from,
                                    true
                                )
                            }
                        />
                    )}
                </div>
                <div className="col-md-10">
                    <a
                        href={infoRoute}
                        onClick={(e) =>
                            navigateRouteClickHandler(
                                infoSectionBasedOnInviteFromType,
                                e,
                                invitation.from,
                                true
                            )
                        }
                        className="lh-1"
                    >
                        <p>
                            <small>
                                {invitation.name} wants to connect with you.
                            </small>
                        </p>
                    </a>
                    {invitation.message && (
                        <div className="card my-3 rounded-1">
                            <div className="card-header contact-message-header rounded-top-1 py-0 fw-bold">
                                <i className="fa-solid fa-envelope me-1"></i>{" "}
                                Note from {invitation.name}
                            </div>
                            <div className="card-body contact-message py-2">
                                {renderMessage()}
                            </div>
                        </div>
                    )}
                    <div className="row mt-2">
                        {state === "working" ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                        ) : (
                            <>
                                <div className="col-6">
                                    <button
                                        className="btn btn-primary btn-sm border-0 rounded py-0 w-100"
                                        onClick={acceptInviteClickHandler}
                                    >
                                        <small>Accept</small>
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className="btn btn-secondary btn-sm border-0 rounded py-0 w-100"
                                        onClick={rejectInviteClickHandler}
                                    >
                                        <small>Reject</small>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const DashboardNotifications = () => {
    const {
        invitationsList,
        acceptInvitationClickHandler,
        rejectInvitationClickHandler,
        messagesList,
        notificationsNumber,
    } = useNotificationContext();

    return (
        <div className="list-group">
            {notificationsNumber === 0 && (
                <p className="px-2">
                    <small>You have no notifications.</small>
                </p>
            )}
            {invitationsList.length > 0 &&
                invitationsList.map((invitation, index) => (
                    <Notification
                        invitation={invitation}
                        acceptClickHandler={acceptInvitationClickHandler}
                        rejectClickHandler={rejectInvitationClickHandler}
                        key={"invitation_" + index}
                    />
                ))}
            {messagesList.length > 0 &&
                messagesList.map((msg, index) => (
                    <Messages message={msg} key={"message_" + index} />
                ))}
        </div>
    );
};
