import { TProcessStates } from "../../lib/types/general";
import { Spinner } from "../sections/backoffice/common/spinner";

type TIncognitoModeProps = {
    onClickHandler: React.MouseEventHandler<HTMLButtonElement>;
    enabled: boolean;
    workingState: TProcessStates;
};
export const IncognitoButton = ({
    onClickHandler,
    enabled,
    workingState,
}: TIncognitoModeProps) => {
    return (
        <>
            <button
                type="button"
                className="btn btn-dark border border-light mt-2 px-4 d-flex align-items-center justify-content-between form-check form-switch flex-wrap"
                data-toggle="modal"
                data-target="#incognitoModal"
            >
                {workingState !== "done" ? (
                    <div className="my-lg-1" style={{ width: "14.3rem" }}>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div className="d-flex align-items-center form-check-label">
                            {enabled ? (
                                <i className="fa-solid fa-eye-slash me-2"></i>
                            ) : (
                                <i className="fa-solid fa-eye me-2"></i>
                            )}{" "}
                            <span className=" fw-bold mb-0">
                                Incognito Mode
                            </span>
                        </div>
                        <input
                            className="form-check-input ms-2 my-0 my-lg-2"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={enabled}
                        />
                    </>
                )}
            </button>

            <div
                className="modal fade"
                id="incognitoModal"
                tabIndex={-1}
                aria-labelledby="incognitoModal"
                aria-hidden="false"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-md-down">
                    <div className="modal-content bg-dark text-white p-3">
                        <div className="modal-header border-0 border">
                            <h1
                                className="modal-title fs-5"
                                id="incognitoModal"
                            >
                                {" "}
                                {enabled ? (
                                    <>
                                        <i className="fa-solid fa-eye me-3"></i>
                                    </>
                                ) : (
                                    <>
                                        <i className="fa-solid fa-eye-slash me-3"></i>
                                    </>
                                )}
                                {enabled
                                    ? "Deactivate Incognito Mode"
                                    : "Activate Incognito Mode"}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body ">
                            {enabled ? (
                                <>
                                    If you deactivate Incognito Mode, all your
                                    profile information will be visible to labs.
                                    <br /> <br />
                                    <strong>
                                        Are you sure you want to deactivate
                                        Incognito Mode?
                                    </strong>
                                </>
                            ) : (
                                <>
                                    <strong>
                                        You're About to Turn On Incognito Mode
                                    </strong>
                                    <br /> <br />
                                    When you activate incognito mode, here's
                                    what will happen:
                                    <br /> <br />
                                    <ul>
                                        {" "}
                                        <strong>
                                            {" "}
                                            <i className="fa-solid fa-circle-exclamation me-2"></i>
                                            Limited Profile Visibility:
                                        </strong>{" "}
                                        Only your skills and the personal
                                        profile section will remain visible to
                                        labs. These sections are highlighted in
                                        your profile, while everything darkened
                                        will be hidden.
                                    </ul>
                                    <ul>
                                        {" "}
                                        <strong>
                                            {" "}
                                            <i className="fa-solid fa-circle-exclamation me-2"></i>
                                            Exceptions for Labs in Your Contact
                                            List:{" "}
                                        </strong>{" "}
                                        Labs in your contact list will continue
                                        to see your full profile.
                                    </ul>
                                    <ul>
                                        {" "}
                                        <strong>
                                            {" "}
                                            <i className="fa-solid fa-circle-exclamation me-2"></i>
                                            Anonymous Search and Matches:{" "}
                                        </strong>{" "}
                                        If a lab searches for you by name or
                                        sees you in match suggestions, you will
                                        appear as "Incognito Tech."
                                    </ul>
                                    <br />
                                    This mode gives you control over your
                                    privacy while staying visible for potential
                                    job opportunities.
                                    <br /> <br />
                                    <strong>
                                        Are you sure you want to activate
                                        Incognito Mode?
                                    </strong>
                                </>
                            )}
                        </div>
                        <div className="modal-footer border-0 border">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {enabled
                                    ? "No, Stay Incognito"
                                    : "No, Stay Visible"}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={onClickHandler}
                                data-dismiss="modal"
                            >
                                {enabled
                                    ? "Yes, Show My Profile"
                                    : "Yes, Hide My Profile"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
